<template>
  <!-- END TOP AREA  -->
  <div class="home-main-bg d-flex align-items-center justify-content-center">
    <!--    <span class="fs-1">Flight Companion By Trepr</span>-->
  </div>

  <div class="container">
    <div class="row margin-top-0421">
      <div class="col-sm-12">
        <h1 class="text-dark">Fly with Treprurance</h1>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-sm-12">
        <p class="fs-4 text-muted">
          Whether you are a senior traveler, someone who doesn't know a language, a senior citizen,
          or just someone who's not up for the rigors of flying alone, Trepr's flight companion
          service is the answer for you.
        </p>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-md-6">
        <i class="fa-users fas circle-yes circle-yes-orange icon-half"></i> <br />
        <button class="btn text-white bg-orange mt-3 btn-lg rounded-pill">Find a companion</button>
      </div>
      <div class="col-md-6">
        <i class="fa-hand-holding-usd fas circle-yes circle-yes-orange icon-half"></i><br />
        <button class="btn text-white bg-orange mt-3 btn-lg rounded-pill">Be a companion</button>
      </div>
    </div>
    <hr class="margin-y-0421" />
    <div class="row text-center mb-4">
      <div class="col-md-12">
        <h1 class="text-dark">How it works</h1>
      </div>
      <div class="col-md-12">
        <p class="fs-4 text-muted">
          We provide travel companionship so you can live life to the fullest. We follow the 3 step
          process
        </p>
      </div>
    </div>
    <div class="row text-center mb-3">
      <div class="col-md-4">
        <i class="fa-search-location fas circle-yes circle-yes-orange icon-third"></i>

        <h3 class="text-dark mt-3">Destination</h3>
        <p class="fs-6 text-muted">
          Trepr's travel buddy finder services will show a list of eligible travelers traveling to
          the same destination who can accompany you as per the travel plan provided by you.
        </p>
      </div>
      <div class="col-md-4">
        <i class="fa-user-plus fas circle-yes circle-yes-orange icon-third"></i>
        <h3 class="text-dark mt-3">Find travellers</h3>
        <p class="fs-6 text-muted">
          A person can communicate with the international travel companion via instant messaging
          services to know the traveler accompanying them better.
        </p>
      </div>
      <div class="col-md-4">
        <i class="fa-handshake fas circle-yes circle-yes-orange icon-third"></i>
        <h3 class="text-dark mt-3">Deal</h3>
        <p class="fs-6 text-muted">
          This travel companion will be assisting you throughout the journey to make sure you have a
          safe and stress-free flight from beginning to end.
        </p>
      </div>
    </div>
    <hr class="my-5" />
    <div class="row text-center mb-4">
      <div class="col-md-12">
        <h1 class="text-dark">You Got Questions?</h1>
      </div>
      <div class="col-md-12">
        <p class="fs-4 text-muted">We got the Answers | Read our FAQs</p>
      </div>
    </div>

    <div class="accordion accordion-flush" id="accordionQa">
      <div class="accordion-item" v-for="i in $data.qa.length" :key="i">
        <h4 class="accordion-header" :id="'flushr-' + i">
          <button
            :aria-controls="'qar' + i"
            aria-expanded="false"
            class="accordion-button collapsed"
            :data-bs-target="'#qar' + i"
            data-bs-toggle="collapse"
            type="button"
          >
            {{ $data.qa[i - 1].title }}
          </button>
        </h4>
        <div
          :aria-labelledby="'flushr-' + i"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionQa"
          :id="'qar' + i"
        >
          <div class="accordion-body">
            <div
              v-html="
                $data.qa[i - 1].content.replace(
                  /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
                  '$1' + '<br />' + '$2'
                )
              "
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row margin-top-0421">
      <div class="col-sm-12">
        <h1 class="text-dark text-center">Customer Experiences</h1>
      </div>
    </div>
    <div class="row 1 mb-5">
      <div class="col-sm-12 blockquote-wrapper">
        <div class="blockquote-customer my-0 bg-light text-muted fs-5 py-4 px-3">
          First time using the Trepr site and will be back. My traveller was able to purchase
          everything I asked for and cheaper in China. It cost way less than to have it delivered
          through Trepr than
        </div>
      </div>
      <div class="col-sm-12 mt-4">
        <div class="d-inline-block">
          <img src="/img/home/april2021/customer2.png" width="40" height="40" />
          <strong> Rina Khanna</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex';
import 'bootstrap';
import '@popperjs/core';

export default {
  name: 'FindCompanion2',
  data: function () {
    return {
      qa: [],
    };
  },
  async created() {
    document.title = 'Trepr - Find International Flight Companion | Airport & Travel Companion';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        "Trepr offers flight companion services to the senior traveler, someone who doesn't know a language, a senior citizen, or just someone who's not up for the rigors of flying alone."
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr,Travel companion,  Flight companionship, Flight companion for elders, Earn while traveling, Travel and Earn'
      );
    let self = this;
    // console.log("state $store ", this.$store.state)
    let apiUrl = process.env.VUE_APP_API_URL;

    await fetch(apiUrl + 'common/get-qa', {
      method: 'POST',
      headers: {
        // 'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
      },
      body: 'type=1',
    })
      .then((response) => response.json())
      .then((resData) => {
        // console.log('Success:', resData);
        // let messages = resData.messages
        if (resData.status == 'error') {
          alert('error while geting data ' + resData.message);
          return;
        }

        self.$data.qa = resData.qa;
      })
      .catch(function (err) {
        console.log('error', err);
      });
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/new/page/findcompanion';
</style>
